var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import Sprite from "../components/Sprite.vue";
import { computed } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'Icon',
    props: {
        name: { type: String, required: true },
        size: { type: Number, default: 16 },
        className: String,
    },
    setup: function (__props) {
        var props = __props;
        var SIZES = {
            16: 's',
            20: 'm',
            24: 'l',
            8: 'xs',
        };
        var className = computed(function () { return __spreadArray([
            "icon--".concat(SIZES[props.size])
        ], __read((props.className ? [props.className] : [])), false); });
        return function (_ctx, _cache) {
            return (_openBlock(), _createElementBlock("span", {
                class: _normalizeClass(["icon", className.value])
            }, [
                _createVNode(Sprite, {
                    name: __props.name,
                    size: __props.size
                }, null, 8 /* PROPS */, ["name", "size"])
            ], 2 /* CLASS */));
        };
    }
});
